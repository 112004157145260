<template>
  <v-container id="home-view" fluid tag="section">
  </v-container>
</template>

<script>
export default {
  name: 'HomeView',
  metaInfo: {
    titleTemplate: 'TheStableManager',
  },
};
</script>
